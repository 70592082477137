import { useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import './page-not-found.scss';

export const PageNotFound = () => {
  const { language } = useI18next();

  return (
    <div className="page-not-found">
      <div className="content">
        {
          language === 'nl' &&
            <>
                <h2>404</h2>
                <span>Pagina niet gevonden</span>
                <p>De pagina die je zocht kunnen we niet vinden.</p>
            </>
        }
        {
          language === 'en' &&
            <>
                <h2>404</h2>
                <span>Page not found</span>
                <p>We can't find the page you were looking for.</p>
            </>
        }
      </div>
    </div>
  );
};
