import * as React from 'react';
import Layout from '../components/layout/layout';
import '../assets/css/main.scss';
import { graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { PageNotFound } from '../components/page-not-found/page-not-found';

const NotFoundPage = () => {
  const { language } = useI18next();
  return (
    <Layout pageTitle={language === 'nl' ? 'Pagina niet gevonden' : 'Page not found'}>
      <PageNotFound/>
    </Layout>
  );
};

export default NotFoundPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
